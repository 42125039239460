.DisabledMessage {
    height: 100vh;
    position: relative;
    background-color: #e35c3c;

}

.DisabledMessage div {   
    width: 200px;
    text-align: center;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.DisabledMessage img {
    display: block;
    width: 100px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
}

.DisabledMessage div p {
    display: block;
    width: 200px;
}