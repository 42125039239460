.ErrorMessage {
    height: 100vh;
    position: relative;
    background-color: white;

}

.ErrorMessage div {   
    width: 200px;
    text-align: center;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.ErrorMessage img {
    display: block;
    width: 50px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
}

.ErrorMessage div p {
    display: block;
    width: 200px;
}