@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

$deep-orange: #D16B00;
$red: #DA2C01;
$purple: #B43CAC;
$blue: #365EF4;
$danger: #FE4D01;
$orange: #ED7D07;
$green: #36C28A;
$dark-blue: #385797;
$light-blue: #22B0FC;
$info: $light-blue;
$success: $green;
$primary: $dark-blue;
$warning: $orange;

$theme-colors: (
        "primary": $primary,
        "info": $info,
        "success": $success,
        "warning": $warning,
        "danger": $danger,
        "blue": $blue,
        "purple": $purple,
        "red": $red,
        "deep-orange": $deep-orange,
        "white": $white
);

$body-color: #333333;

$font-family-sans-serif: 'Open Sans', sans-serif;
$font-size-base: 1.2rem;
