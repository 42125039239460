@import "./variables";

.block {
  cursor: pointer;
  outline: none !important;
  border: none;
  font-weight: 600;

  //&:hover {
  //  background-color: $gray-700;
  //}
}

.success {
  background-color: #c5e8da;
  border: 1px solid $success;
  color: darken($success, 5%);
  &:hover {
    background-color: darken(#c5e8da, 5%);
  }
}

.warning {
  background-color: #ffbe7a;
  border: 1px solid $warning;
  color: darken($warning, 5%);
  &:hover {
    background-color: darken(#ffbe7a, 5%);
  }
}

.info {
  background-color: #c6ebff;
  border: 1px solid $info;
  color: darken($info, 5%);
  &:hover {
    background-color: darken(#c6ebff, 5%);
  }
}
