@import "./variables";

.result {
  background-color: $info !important;
  cursor: pointer;

  &:hover, &:active {
    background-color: lighten($info, 5%) !important;

    border: $border-width solid darken($border-color, 10%) !important;
  }
}
