.badge {
  font-size: 100% !important;
}

.chainBuilder {
  overflow-x: auto;
  flex-wrap: nowrap;
  flex-direction: row-reverse;
  //@media (min-width: 768px) {
  //  overflow-x: visible;
  //  flex-wrap: wrap;
  //  flex-direction: row;
  //}
}

.propertyCard {
    min-width: 200px;
    min-height: 140px;
}
